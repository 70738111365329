import i18n from 'i18next';
import de from './translations/de'
import en from './translations/en'
import { initReactI18next } from 'react-i18next';
import { Translation } from './store/interfaces';

const resources = {
    de,
    en
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'de',
    
        interpolation: {
            escapeValue: false
      }
});

export function translate(text: Translation, i: typeof i18n): string {
    if(text !== undefined && text !== null && text.hasOwnProperty(i.language)) {
        return (text as any)[i.language] as string;
    }
    return `Translation not found for language ${i.language}.`;
}

export default i18n;