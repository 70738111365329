let translation = {
    translation: {
        title: 'International Centre Appointment Booking',
        user: {
            firstname: "First Name",
            lastname: "Last Name",
            email: "Email",
            emailRepeat: "Repeat Email",
            invalidEmail: "Please enter a valid email address.",
            invalidEmailRepeat: "Both fields containing email addresses have to match.",
            phone: "Phone",
            matriculationNumber: "Matriculation Number",
            additionalInfo: "Please leave a short message ({{current}} of {{max}} characters)",
            consent: "I have read the <1>data protection declaration</1> and I agree to my data being processed."
        },
        selection: {
            type: {
                title: "Type of consultation",
                intro: "For this subject, you can choose between an on-site meeting (Presence) or an online meeting (Online).",
                presence: "Face-to-face",
                online: "Online",
                presenceOnly: "This appointment takes place on-site.",
                onlineOnly: "This appointment takes place online.",
                outro: "You will receive all the necessary information to take part in the consultation (location, link to the online meeting, etc.) via email."
            },
            subject: {
                title: "Subject",
                none: "No topics were found!"
            },
            dateslots: {
                none: "No {{chosen}} appointments are available within the next {{days}} days for your chosen subject. Please choose an {{notChosen}} appointment or try it again later.",
                presence: "face-to-face",
                online: "online",
                change: "Change Type of Consultation"
            },
            date: {
                info: "You can book an appointment within the next {{days}} days."
            },
            time: {
                info: 'All times use the Central European Time (CET)'
            },
            placeholder: {
                topic: "Please choose a topic.",
                date: "Please choose a date.",
                time: "Please choose a time.",
                user: "Enter your data.",
                consultationType: "Please choose your type of consultation."
            },
            required: "Specification required"
        },        
        contact: {
            title: "CONTACT",
            subtitle: "International Centre",
            street: "Goethestraße 3-5",
            location: "04109 Leipzig"
        },
        overview: {
            title: "MY SELECTION",
            book: "Book an appointment"
        },
        add: {
            app: {            
                title: "Use the entdecker – Abenteuer Ausland app",
                subTitle: "for planning and realisation of your studies abroad",
                url: "https://www.uni-leipzig.de/international/studium-und-praktikum-im-ausland/entdecker-app/"
            },
            stories: {            
                title: "Read the Entdeckerstorys",
                subTitle: "to hear about experiences by others",
                url: "https://www.uni-leipzig.de/entdeckerstorys/?ADMCMD_previewWS=1"
            }
        },
        commit: {
            almost: "Almost there",
            error: "An error occurred:",
            committing: "Your data is being committed ...",
            info: "You will receive an email with the confirmation link shortly. You must confirm the appointment within the next 30 minutes.",
            tryAgain: "Try again",
            backToReferrer: "Back to Uni-Leipzig"
        },
        errors: {
            network: "A connection to the booking system could not be established",
            system: "There was an error in the booking system. Please try again.",
            mail: "There was an error sending the confirmation email. Please check the supplied email address and try again.",
            alreadyBooked: "There already exists an appointment of this subject with this email address!",
            alreadyOver: "This slot is not available anymore.",
            noslot: "There was no open slot found in this time period.",
            subject: "Your chosen subject could not be found.",
            token: "Unfortunately the booking took too much time. Please reload this page and try again."
        },
        impressum: {
            show: 'Imprint and data privacy',
            back: 'Back to booking an appointment'
        }
    }
};

export default translation;